import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import {
  Row,
  Col,
  Popover,
  Button,
  Input,
  Select,
  Radio,
  Modal,
  Steps,
  Form,
  Checkbox,
  Tag,
  DatePicker,
  Card,
  message,
} from "antd";
import {
  copyObject,
  getCurrentlocaleText,
  getDefaultTimezone,
  getScanType,
  isoToDateString,
} from "../../Core/Utils";
import Icons from "../UI-Components/Icons";
import TableLayout from "../layout/TableLayout";
import DeviceDiscoveryController from "../../controller/DeviceDiscoveryController";
import NetworkController from "../../controller/NetworkController";
import LoadingComponent from "components/UI-Components/LoadingComponent";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@view/pro-solid-svg-icons";

const { Search } = Input;
const { Step } = Steps;
const { Option } = Select;
const weeklyOptions = [
  {
    label: "Mon",
    value: "mo",
  },
  {
    label: "Tue",
    value: "tu",
  },
  {
    label: "Wed",
    value: "we",
  },
  {
    label: "Thu",
    value: "th",
  },
  {
    label: "Fri",
    value: "fr",
  },
  {
    label: "Sat",
    value: "sa",
  },
  {
    label: "Sun",
    value: "su",
  },
];
const scanType = getScanType();
const frequencyType = [
  {
    label: "Run Now",
    value: "runnow",
  },
  {
    label: "Recurrence",
    value: "recurrence",
  },
];

@inject(
  "AuthStore",
  "UiStore",
  "InodeViewModel",
  "OrgViewModel",
  "DeviceDiscoveryViewModel",
  "NetworkViewModel",
  "TimezoneViewModel",
  "ClusterViewModel",
)
@observer
export default class DeviceDiscoveryScan extends Component {
  constructor(props) {
    super(props);
    this.inodeModel = this.props.InodeViewModel;
    this.orgModel = this.props.OrgViewModel;
    this.networkModel = this.props.NetworkViewModel;
    this.deviceDisModel = this.props.DeviceDiscoveryViewModel;
    this.clusterModel = this.props.ClusterViewModel;
    this.timezoneModel = this.props.TimezoneViewModel;
    this.timezone =
      this.timezoneModel.userTimezone ||
      this.timezoneModel.orgTimezone ||
      getDefaultTimezone().value;

    this.state = {
      searchText: "",
      status: "inprogress",
      sorterInfo: { sorter: {}, sort: {} },
      filterInfo: null,
      isModalOpen: false,
      currentStep: 0,
      frequency: "",
      frequencyInterval: "",
      timeSel: moment(),
      isEditModalLoading: false,
      isEditModalOpen: false,
      isDeleteModalLoading: false,
      isDeleteModalOpen: false,
      isRunNowModalOpen: false,
      frequencyIntervalEdit: "",
      iNodeNetworks: [],
      stepData: [],
      createScanLoading: false,
      devices: [],
      filteredDevices: [],
      selectedRows: [],
      editData: {},
      selScanType: "",
      selectedRowKeys: [],
      deviceDiscoverySummary: [],
      selectedCard: null,
    };
    this.orgId = this.props.match.params.orgId;
    this.nodeId = this.props.match.params.nodeId
      ? this.props.match.params.nodeId
      : this.props.nodeId;
    this.clusterId = this.props.match.params.clusterId;
    this.requestFrom = this.props.match.params.clusterId ? "cluster" : "node";

    this.formRef = React.createRef();
    this.editFormRef = React.createRef();
    this.searchFormRef = React.createRef();
    this.refreshData = "";
  }

  componentDidMount() {
    this.deviceDisModel.resetScans([]);
    this.deviceDisModel.setLoading(true);
    this.getScans({});
    this.updateNetworkData();
    this.getScansSummary({});
    clearInterval(this.refreshData);
    this.refreshData = setInterval(() => {
      this.getScans({});
    }, 90 * 1000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.orgId !== this.orgId ||
      prevProps.match.params.nodeId !== this.nodeId ||
      prevProps.match.params.clusterId !== this.clusterId
    ) {
      this.deviceDisModel.resetScans([]);
      this.getScans({});
      this.updateNetworkData();
      this.getScansSummary({});
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshData);
  }

  getScans = (params = {}, foreceCall = false) => {
    params = {
      ...params,
      search: this.state.searchText,
      profile: this.state.selScanType,
    };
    DeviceDiscoveryController.getScans(
      this.clusterId ? this.clusterId : this.nodeId,
      this.requestFrom,
      params,
      foreceCall,
    ).then(resp => {
      if (resp) {
        this.setState({
          devices: resp.results,
          filteredDevices: resp.results,
        });
      }
    });
  };

  lazyLoad = () => {
    if (this.deviceDisModel.pageable.next) {
      this.deviceDisModel.setLoading(true);
      let params = {};
      params.page = this.deviceDisModel.pageable.page + 1;
      this.getScans(params);
    }
  };

  getScansSummary = (params = {}, foreceCall = false) => {
    let id = this.requestFrom == "cluster" ? this.clusterId : this.nodeId;
    DeviceDiscoveryController.getDeviceDiscoverySummary(
      this.requestFrom,
      id,
    ).then(res => {
      if (res && res.scans) {
        this.setState({
          deviceDiscoverySummary: res.scans,
        });
      }
    });
  };

  updateNetworkData = () => {
    let params = this.props.params || {
      node_id: this.nodeId,
      size: 1,
      page: 0,
    };

    NetworkController.getNetworks(params, true, false, true).then(resp => {
      if (resp && resp.length > 0) {
        let totalCount = this.networkModel.pageable.total_count / 100;
        let availableNetworks = [];
        let loading = true;

        if (totalCount > 0) {
          let allParams = [];
          for (let page = 0; page < totalCount; page++) {
            let p = {
              size: 100,
              page: page,
            };
            if (this.requestFrom == "cluster") {
              p.cluster_id = this.clusterId;
            } else {
              p.node_id = this.nodeId;
            }
            allParams.push(p);
          }

          NetworkController.getAllNetworksForDropdown(allParams, loading).then(
            networkResp => {
              if (networkResp) {
                availableNetworks = availableNetworks.concat(networkResp);
                let iNodeNetworks = availableNetworks.filter(networks => {
                  if (!networks.is_wan_network && !networks.is_inheritance)
                    return networks;
                });
                this.setState({ iNodeNetworks });
              }
            },
          );
        }
      }
    });
  };

  showModal = () => {
    this.setState({ isModalOpen: true });
  };

  handleNext = () => {
    this.formRef.current.validateFields().then(values => {
      let stepData = this.state.stepData;
      stepData[this.state.currentStep] = values;
      this.setState({
        currentStep: this.state.currentStep + 1,
        stepData: stepData,
      });
    });
  };

  getFormDataForStep = step => {
    return this.state.stepData[step - 1] || {};
  };

  handlePrev = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };

  resetCreateScanData = () => {
    this.setState({
      currentStep: 0,
      stepData: [],
      frequency: "",
      frequencyInterval: "",
      timeSel: moment(),
      selectedCard: null,
    });
  };

  handleFinish = () => {
    let { stepData } = this.state;
    let postData = {
      name: stepData[3] && stepData[3].nameOfTheScan, // "scanmonthly",
      profile: stepData[1] && stepData[1].scanType, // "bacnet",
    };

    if (stepData[2] && stepData[2].frequency == "runnow") {
      postData.schedule = {
        run_once: true,
      };
    }

    if (stepData[2] && stepData[2].frequency == "recurrence") {
      postData.schedule = {
        recurrence: {
          interval: 1,
          start_time: moment
            .utc(stepData[2].time)
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        },
      };

      if (stepData[2].interval == "daily") {
        postData.schedule.recurrence.frequency = "daily";
        postData.schedule.recurrence.interval = stepData[2].RepeatEvery;
      }

      if (stepData[2].interval == "weekly") {
        postData.schedule.recurrence.frequency = "weekly";
        postData.schedule.recurrence.by_day = stepData[2].weekly;
      }

      if (stepData[2].interval == "monthly") {
        postData.schedule.recurrence.frequency = "monthly";
        postData.schedule.recurrence.by_month_day = stepData[2].onDays;
      }
    }

    this.setState({ createScanLoading: true });
    let nwId = stepData[0] && stepData[0].network;
    DeviceDiscoveryController.createScan(nwId, postData)
      .then(response => {
        this.setState({ createScanLoading: false, isModalOpen: false });
        this.resetCreateScanData();
        this.props.UiStore.setSuccessMessage(
          getCurrentlocaleText("general.api.create.success.text", {
            0: getCurrentlocaleText("scan.text"),
          }),
        );
        this.getScans({}, true);
      })
      .catch(error => {
        this.setState({ createScanLoading: false, isModalOpen: false });
        this.resetCreateScanData();
        this.props.UiStore.setErrorMessage(
          getCurrentlocaleText("error.create.scan.text"),
        );
      });
  };

  confirmDelete = () => {
    this.setState({ isDeleteModalOpen: true });
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  handleDelete = () => {
    this.setState({ isDeleteModalLoading: true });
    DeviceDiscoveryController.deleteScan(this.state.selectedRows)
      .then(response => {
        this.setState({
          isDeleteModalLoading: false,
          isDeleteModalOpen: false,
          selectedRows: [],
        });
        this.getScans({}, true);
      })
      .catch(error => {
        this.setState({
          isDeleteModalLoading: false,
          isDeleteModalOpen: false,
          selectedRows: [],
        });
        this.getScans({}, true);
      });
  };

  runNowScan = () => {
    let selRow = this.state.selectedRows[0];
    let payLoad = {};

    DeviceDiscoveryController.runNowScan(selRow.network.id, selRow.id, payLoad)
      .then(response => {
        this.props.UiStore.setSuccessMessage(
          getCurrentlocaleText("general.api.schedule.success.text", {
            0: selRow.name + " scan ",
          }),
        );
        this.getScans({});
        this.setState({
          isRunNowModalLoading: false,
          isRunNowModalOpen: false,
          selectedRowKeys: [],
        });
      })
      .catch(error => {
        this.setState({
          isRunNowModalLoading: false,
          isRunNowModalOpen: false,
          selectedRowKeys: [],
        });
      });
  };

  confirmRunNow = () => {
    this.setState({ isRunNowModalOpen: true });
  };

  handleRunNowCancel = () => {
    this.setState({ isRunNowModalOpen: false });
  };

  handleRunNow = () => {
    this.setState({ isRunNowModalLoading: true });
    this.runNowScan();
  };

  handleCancel = () => {
    this.setState({ isModalOpen: false });
    this.resetCreateScanData();
  };

  onHandleFreqChange = e => {
    if (e && e.target && e.target.value) {
      this.setState({
        frequency: e.target.value,
      });

      if (e.target.value == "runnow") {
        this.setState({
          frequencyInterval: "",
        });
      }
    }
  };

  onFrequencyIntervalChange = val => {
    this.setState({ frequencyInterval: val });
  };

  onFrequencyEditIntervalChange = changedVal => {
    let val = this.state.editData;
    val.schedule.recurrence.frequency = changedVal;
    this.setState({ editData: val });
  };

  showEditModal = () => {
    let editData = copyObject(this.state.selectedRows[0]);
    editData.recurrenceType =
      editData && editData.schedule && editData.schedule.recurrence
        ? "recurrence"
        : "runnow";
    this.setState({
      isEditModalOpen: true,
      editData: editData,
    });
  };

  handleEditCancel = () => {
    this.setState({ isEditModalOpen: false });
  };

  handleEditFinish = () => {
    this.editFormRef.current.validateFields().then(values => {
      if (values) {
        let postData = {
          name: values.nameOfTheScan,
          profile: values.scanType,
        };

        if (values.frequency == "runnow") {
          postData.schedule = {
            run_once: true,
          };
        } else if (values.frequency == "recurrence") {
          postData.schedule = {
            recurrence: {
              interval: 1,
              start_time: moment
                .utc(values.time)
                .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            },
          };

          if (values.interval == "daily") {
            postData.schedule.recurrence.frequency = "daily";
            postData.schedule.recurrence.interval = values.RepeatEvery;
          }

          if (values.interval == "weekly") {
            postData.schedule.recurrence.frequency = "weekly";
            postData.schedule.recurrence.by_day = values.weekly;
          }

          if (values.interval == "monthly") {
            postData.schedule.recurrence.frequency = "monthly";
            postData.schedule.recurrence.by_month_day = values.onDays;
          }
        }

        this.setState({
          isEditModalLoading: true,
        });
        DeviceDiscoveryController.editScan(this.state.selectedRows[0], postData)
          .then(response => {
            this.getScans({}, true);
            this.setState({
              isEditModalOpen: false,
              isEditModalLoading: false,
              selectedRows: [],
            });
          })
          .catch(error => {
            this.getScans({}, true);
            this.setState({
              isEditModalOpen: false,
              isEditModalLoading: false,
              selectedRows: [],
            });
          });
      }
    });
  };

  onFrequencyIntervalEdit = val => {
    this.setState({ frequencyIntervalEdit: val });
  };

  optionList(totalCount, text) {
    const options = [];
    for (let i = 1; i <= totalCount; i++) {
      options.push(
        <Option key={i} value={i}>
          {i}
          {text && " " + text}
        </Option>,
      );
    }
    return options;
  }

  getNWName = nwId => {
    if (nwId) {
      let resultObj =
        this.state.iNodeNetworks &&
        this.state.iNodeNetworks.find(obj => obj.id == nwId);
      return resultObj && resultObj.name ? resultObj.name : "-";
    }
  };

  getScanName = scanCode => {
    if (scanCode) {
      let resultObj = scanType && scanType.find(obj => obj.value == scanCode);
      return resultObj && resultObj.label ? resultObj.label : "-";
    }
  };

  getFrequencyName = val => {
    if (val) {
      let resultObj = frequencyType.find(obj => obj.value == val);
      return resultObj && resultObj.label ? resultObj.label : "-";
    }
  };

  getWeekDayName = selDay => {
    if (selDay) {
      let result = [];
      for (const day of weeklyOptions) {
        if (selDay.includes(day.value)) {
          result.push(day.label);
        }
      }
      return result;
    }
  };

  onScanTypeChange = e => {
    this.setState(
      {
        selScanType: e,
      },
      () => {
        this.getScans({}, true);
      },
    );
  };

  onSearchChange = e => {
    if (e && e.target && (e.target.value || e.target.value == "")) {
      this.setState(
        {
          searchText: e.target.value,
        },
        () => {
          this.getScans({}, true);
        },
      );
    }
  };

  onScanStatusChange = e => {
    if (e && e.target && e.target.value) {
      this.setState({ status: e.target.value });
    }
  };

  redirectReport = row => {
    if (this.requestFrom == "cluster") {
      this.props.history.push(
        `/orgs/${this.orgId}/clusters/${this.clusterId}/devicediscovery/reports/` +
          row.id,
      );
    } else {
      this.props.history.push(
        `/orgs/${this.orgId}/inodes/${this.nodeId}/devicediscovery/reports/` +
          row.id,
      );
    }
    this.props.handleTabChange("reports");
  };

  handleTableChange = (record, selected, selectedRows) => {
    this.setState({ selectedRows });
  };

  onSelectAllChange = (selected, selectedRows, changeRows) => {
    this.setState({ selectedRows });
  };

  disabledDate = current => {
    return current && current.valueOf() < Date.now();
  };

  getSummary = (type, searchKey) => {
    let scanObj = this.state.deviceDiscoverySummary.find(
      obj => obj.profile == type,
    );
    if (scanObj && scanObj.count && searchKey == "count") {
      return scanObj.count;
    } else if (scanObj && scanObj.last_scan && searchKey == "last_scan") {
      return moment.utc(scanObj.last_scan).fromNow();
    } else {
      return 0;
    }
  };

  getCount = (type, searchKey) => {
    let scanObj = this.state.deviceDiscoverySummary.find(
      obj => obj.profile === type,
    );

    if (scanObj && searchKey === "count") {
      const count = scanObj.count === 0 ? "n/a" : scanObj.count;
      const color = scanObj.count === 0 ? "#D3D5D9" : "inherit";
      return <span style={{ color }}>{count}</span>;
    } else if (scanObj && scanObj.last_scan && searchKey === "last_scan") {
      return <span>{moment.utc(scanObj.last_scan).fromNow()}</span>;
    } else {
      return (
        <span style={{ color: "#D3D5D9" }}>
          {getCurrentlocaleText("na.text")}
        </span>
      );
    }
  };

  handleCardSelect = e => {
    this.setState({ selectedCard: e.target.value });
  };

  BacnetScanPopover = () => (
    <Popover
      placement="right"
      content={
        <div
          className="pb-10"
          style={{
            width: "240px",
            height: "200px",
            padding: "16px",
          }}
        >
          {getCurrentlocaleText("bacnet.scan.help")}
          <div style={{ marginTop: "10px" }}>
            {getCurrentlocaleText("bacnet.scan.help.text1")}
          </div>
        </div>
      }
      overlayClassName="device-discovery-popover"
    >
      <span>
        <Icons type="md" name="MdInfoOutline" className="iot-pointer-cursor" />
      </span>
    </Popover>
  );

  DeepScanPopover = () => (
    <Popover
      placement="right"
      content={
        <div
          className="pb-10"
          style={{
            width: "240px",
            height: "220px",
            padding: "16px",
          }}
        >
          <div>{getCurrentlocaleText("deep.scan.help.text")}</div>
          <div style={{ marginTop: "10px" }}>
            {getCurrentlocaleText("deep.scan.help.text1")}
          </div>
        </div>
      }
      overlayClassName="device-discovery-popover"
    >
      <span>
        <Icons type="md" name="MdInfoOutline" className="iot-pointer-cursor" />
      </span>
    </Popover>
  );

  BalancedPopover = () => (
    <Popover
      placement="right"
      content={
        <div
          className="pb-10"
          style={{
            width: "240px",
            height: "200px",
            padding: "16px",
          }}
        >
          <div>{getCurrentlocaleText("balanced.help.text")}</div>
          <div style={{ marginTop: "10px" }}>
            {getCurrentlocaleText("balanced.help.text1")}
          </div>
        </div>
      }
      overlayClassName="device-discovery-popover"
    >
      <span>
        <Icons type="md" name="MdInfoOutline" className="iot-pointer-cursor" />
      </span>
    </Popover>
  );

  BasicScanPopover = () => (
    <Popover
      placement="right"
      content={
        <div
          className="pb-10"
          style={{
            width: "240px",
            height: "128px",
            padding: "16px",
          }}
        >
          {getCurrentlocaleText("basic.scan.help")}
        </div>
      }
      overlayClassName="device-discovery-popover"
    >
      <span>
        <Icons type="md" name="MdInfoOutline" className="iot-pointer-cursor" />
      </span>
    </Popover>
  );

  render() {
    let {
      isModalOpen,
      isEditModalOpen,
      isEditModalLoading,
      editData,
      currentStep,
      frequency,
      frequencyInterval,
      timeSel,
      iNodeNetworks,
      stepData,
      isDeleteModalOpen,
      isDeleteModalLoading,
      isRunNowModalOpen,
      isRunNowModalLoading,
      selectedRows,
      selectedRowKeys,
      createScanLoading,
      status,
      selectedCard,
    } = this.state;
    let scanList = this.deviceDisModel.scanList;

    let isCurrentlyRunning =
      selectedRows[0] &&
      selectedRows[0].status &&
      selectedRows[0].status.state &&
      selectedRows[0].status.state.toUpperCase() == "RUNNING"
        ? true
        : false;

    if (status == "inprogress") {
      scanList = scanList.filter(obj => {
        return (
          (obj &&
            obj.status &&
            obj.status.state &&
            obj.status.state.toUpperCase() == "RUNNING") ||
          (obj &&
            obj.status &&
            obj.status.state &&
            obj.status.state.toUpperCase() == "QUEUED")
        );
      });
    }

    const steps = [
      {
        title: getCurrentlocaleText("general.component.networks.text"),
        key: 1,
        content: (
          <Form
            ref={this.formRef}
            requiredMark={false}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 6,
            }}
            initialValues={this.getFormDataForStep(1)}
          >
            <Form.Item
              label={getCurrentlocaleText("general.component.networks.text")}
              name="network"
              rules={[{ required: true, message: "Network is required" }]}
            >
              <Select allowClear>
                {iNodeNetworks &&
                  Array.isArray(iNodeNetworks) &&
                  iNodeNetworks.map(obj => {
                    return (
                      <Option value={obj.id} key={obj.id}>
                        {obj.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Form>
        ),
      },
      {
        title: getCurrentlocaleText("general.component.scan.type.text"),
        key: 2,
        content: (
          <div className="device-discovery-container scan-type-new">
            <Form
              ref={this.formRef}
              requiredMark={false}
              labelCol={{
                span: 12,
              }}
              wrapperCol={{
                span: 12,
              }}
              initialValues={this.getFormDataForStep(2)}
            >
              <Row className="header-row">
                <b>{getCurrentlocaleText("scan.select.type.text") + ":"}</b>
              </Row>
              <Row className="card-row">
                <Form.Item
                  label=""
                  name="scanType"
                  rules={[{ required: true, message: "Scan Type is required" }]}
                  className="scan-type-new"
                >
                  <Radio.Group
                    className="card-gap"
                    onChange={this.handleCardSelect}
                    value={this.state.selectedCard}
                  >
                    {scanType.map((obj, id) => {
                      return (
                        <Radio
                          value={obj.value}
                          key={id}
                          className={`card-item ${
                            selectedCard == obj.value ? "checked" : ""
                          }`}
                        >
                          <Card>
                            <div className="device-discovery-title-text">
                              {obj.label}
                              <span className="p-5">
                                {obj.value == "basic" &&
                                  this.BasicScanPopover()}
                                {obj.value == "balanced" &&
                                  this.BalancedPopover()}
                                {obj.value == "deep" && this.DeepScanPopover()}
                                {obj.value == "bacnet" &&
                                  this.BacnetScanPopover()}
                              </span>
                            </div>
                            <div className="card-subtitle">
                              {getCurrentlocaleText("scan.sub.useful.text")}
                            </div>
                            <div className="card-content">{obj.desc}</div>
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="tick-mark"
                            />
                          </Card>
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
              </Row>
            </Form>
            <div className="device-discovery-warning-content">
              <Icons
                style={{ fontSize: 18 }}
                className="pr-5 iot-status-yellow"
                type="fa"
                name="FaExclamationTriangle"
              />
              <span>
                {getCurrentlocaleText("device.discovery.warning.text")}
              </span>
            </div>
          </div>
        ),
      },
      {
        title: getCurrentlocaleText("general.component.frequency.text"),
        key: 3,
        content: (
          <Row>
            <Col span={24} className="pb-15">
              <Icons
                style={{ fontSize: 18 }}
                className="pr-5 iot-status-yellow"
                type="fa"
                name="FaExclamationTriangle"
              />
              <span>
                {getCurrentlocaleText("device.discovery.disclaimer.text")}
              </span>
            </Col>
            <Col span={24}>
              <Form
                ref={this.formRef}
                requiredMark={false}
                labelCol={{
                  span: 6,
                }}
                wrapperCol={{
                  span: 9,
                }}
                initialValues={this.getFormDataForStep(3)}
              >
                <Form.Item
                  label="Frequency"
                  name="frequency"
                  rules={[{ required: true, message: "Frequency is required" }]}
                  initialValue={frequency}
                >
                  <Radio.Group
                    onChange={this.onHandleFreqChange}
                    className="d-flex"
                  >
                    {frequencyType.map((obj, id) => {
                      return (
                        <Radio value={obj.value} key={id}>
                          {obj.label}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                </Form.Item>
                {frequency == "recurrence" && (
                  <>
                    <Form.Item
                      label="Interval"
                      name="interval"
                      rules={[
                        { required: true, message: "Interval is required" },
                      ]}
                      initialValue={frequencyInterval}
                    >
                      <Select
                        onChange={this.onFrequencyIntervalChange}
                        allowClear
                      >
                        <Option value="daily">
                          {getCurrentlocaleText("daily")}
                        </Option>
                        <Option value="weekly">
                          {getCurrentlocaleText("weekly")}
                        </Option>
                        <Option value="monthly">
                          {getCurrentlocaleText("monthly")}
                        </Option>
                      </Select>
                    </Form.Item>

                    {/* Daily */}
                    {frequencyInterval == "daily" && (
                      <Form.Item
                        label="Repeat Every"
                        name="RepeatEvery"
                        rules={[
                          {
                            required: true,
                            message: "Repeat Every is required",
                          },
                        ]}
                      >
                        <Select allowClear>
                          {this.optionList(12, " Hour Once")}
                        </Select>
                      </Form.Item>
                    )}

                    {/* Weekly */}
                    {frequencyInterval == "weekly" && (
                      <Form.Item
                        label="Weekly"
                        name="weekly"
                        rules={[
                          { required: true, message: "Weekly is required" },
                        ]}
                      >
                        <Checkbox.Group
                          options={weeklyOptions}
                          onChange={this.onWeeklyChange}
                          className="on-days"
                        />
                      </Form.Item>
                    )}

                    {/* Monthly */}
                    {frequencyInterval == "monthly" && (
                      <Form.Item
                        label="On Days"
                        name="onDays"
                        mode="multiple"
                        rules={[
                          { required: true, message: "On Days is required" },
                        ]}
                      >
                        <Select mode="multiple" allowClear>
                          {this.optionList(31)}
                        </Select>
                      </Form.Item>
                    )}

                    {/* Scheduled Date Time */}
                    <Form.Item
                      label={getCurrentlocaleText(
                        "general.component.scheduled.text",
                      )}
                      name="time"
                      rules={[
                        { required: true, message: "Date/Time is required" },
                      ]}
                      initialValue={timeSel}
                    >
                      <DatePicker
                        showTime={{
                          defaultValue: moment(),
                        }}
                        format="YYYY-MM-DD h:mm A"
                        placeholder={getCurrentlocaleText(
                          "event.startdatefilter.placeholder",
                        )}
                        onChange={value => {
                          this.setState({
                            timeSel: moment(value),
                          });
                        }}
                        disabledDate={this.disabledDate}
                        title={getCurrentlocaleText(
                          "event.startdatefilter.placeholder",
                        )}
                      />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        ),
      },
      {
        title: getCurrentlocaleText("general.component.scan.name.text"),
        key: 4,
        content: (
          <Form
            ref={this.formRef}
            requiredMark={false}
            labelCol={{
              span: 6,
            }}
            wrapperCol={{
              span: 6,
            }}
            initialValues={this.getFormDataForStep(4)}
          >
            <Form.Item
              label="Name of the scan"
              name="nameOfTheScan"
              rules={[
                { required: true, message: "Name of the scan is required" },
                {
                  min: 2,
                  message: getCurrentlocaleText("general.form.validation.min", {
                    0: 2,
                  }),
                },
                {
                  max: 30,
                  message: getCurrentlocaleText("general.form.validation.max", {
                    0: 30,
                  }),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        ),
      },
      {
        title: getCurrentlocaleText("general.component.summary.text"),
        key: 5,
        content: (
          <>
            <Row className="pb-15 device-discovery-warning">
              <Icons
                style={{ fontSize: 18 }}
                className="mr-15 iot-status-yellow"
                type="fa"
                name="FaExclamationTriangle"
              />
              <div className="d-inline-block">
                {getCurrentlocaleText("device.discovery.warning.summary.text")}
              </div>
            </Row>
            <Row className="pb-10">
              <Col span="6">
                <b>
                  {getCurrentlocaleText("general.component.scan.name.text")}
                </b>
              </Col>
              <Col span="6">{stepData[3] && stepData[3].nameOfTheScan}</Col>
            </Row>
            <Row className="pb-10">
              <Col span="6">
                <b>{getCurrentlocaleText("general.component.networks.text")}</b>
              </Col>
              <Col span="6">
                {this.getNWName(stepData[0] && stepData[0].network)}
              </Col>
            </Row>
            <Row className="pb-10">
              <Col span="6">
                <b>
                  {getCurrentlocaleText("general.component.scan.type.text")}
                </b>
              </Col>
              <Col span="6">
                {this.getScanName(stepData[1] && stepData[1].scanType)}
              </Col>
            </Row>
            <Row className="pb-10">
              <Col span="6">
                <b>
                  {getCurrentlocaleText("general.component.frequency.text")}
                </b>
              </Col>
              <Col span="6">
                {this.getFrequencyName(stepData[2] && stepData[2].frequency)}

                {stepData[2] &&
                  stepData[2].frequency == "recurrence" &&
                  stepData[2].interval == "daily" && (
                    <div className="pt-5">
                      {getCurrentlocaleText(
                        "device.discovery.repeat.every.text",
                      )}
                      {stepData[2].RepeatEvery}{" "}
                      {getCurrentlocaleText("device.discovery.hour.once.text")}
                    </div>
                  )}
                {stepData[2] &&
                  stepData[2].frequency == "recurrence" &&
                  stepData[2].interval == "weekly" && (
                    <div className="pt-5">
                      {getCurrentlocaleText("device.discovery.every.week.text")}{" "}
                      {this.getWeekDayName(stepData[2].weekly).join(", ")}
                    </div>
                  )}
                {stepData[2] &&
                  stepData[2].frequency == "recurrence" &&
                  stepData[2].interval == "monthly" && (
                    <div className="pt-5">
                      {getCurrentlocaleText(
                        "device.discovery.every.month.text",
                      )}{" "}
                      {stepData[2].onDays.join(", ")}
                    </div>
                  )}
              </Col>
            </Row>
            {stepData[2] &&
              stepData[2].frequency == "recurrence" &&
              stepData[2].time && (
                <Row className="pb-10">
                  <Col span="6">
                    <b>
                      {getCurrentlocaleText("general.component.scheduled.text")}
                    </b>
                  </Col>
                  <Col span="6">
                    {moment(stepData[2].time).format("DD MMM YYYY, h:mm A")}
                  </Col>
                </Row>
              )}
          </>
        ),
      },
    ];

    const columns = [
      {
        title: getCurrentlocaleText("general.component.scan.name.text"),
        dataIndex: "name",
        render: (text, record, index) => {
          return (
            <div className="justify-space-between pr-10">
              <div>{text}</div>
              <div>
                {record && record.schedule && record.schedule.recurrence && (
                  <Icons type="fa" name="FaRegCalendarCheck" />
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.scan.type.text"),
        dataIndex: "discovery_profile",
        render: (text, record, index) => {
          let scanTypeObj = scanType.find(obj => obj.value == text);
          return (
            <div>
              {scanTypeObj && scanTypeObj.label ? scanTypeObj.label : text}
            </div>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.networks.text"),
        dataIndex: "network",
        render: (network, record, index) => {
          return <span>{network && network.name ? network.name : "-"}</span>;
        },
      },
      {
        title: getCurrentlocaleText("general.component.lastrun.text"),
        dataIndex: "last_successful_run",
        render: (last_successful_run, record, index) => {
          return (
            <span>
              {last_successful_run ? isoToDateString(last_successful_run) : "-"}
            </span>
          );
        },
      },
      {
        title: getCurrentlocaleText("general.component.recurring.details.text"),
        key: "recurringDetails",
        dataIndex: "schedule",
        render: (schedule, record, index) => {
          let returnVal = "";
          if (schedule && schedule.run_once) {
            returnVal = (
              <div className="text-capitalize">
                {getCurrentlocaleText("device.discovery.run.once.text")}
              </div>
            );
          }
          if (
            schedule &&
            schedule.recurrence &&
            schedule.recurrence.frequency &&
            schedule.recurrence.frequency == "daily"
          ) {
            returnVal = (
              <div className="text-capitalize">
                Daily{" "}
                {schedule &&
                  schedule.recurrence &&
                  schedule.recurrence.interval}{" "}
                hour once
              </div>
            );
          }
          if (
            schedule &&
            schedule.recurrence &&
            schedule.recurrence.frequency &&
            schedule.recurrence.frequency == "weekly"
          ) {
            returnVal = (
              <div className="text-capitalize">
                On Every Week{" "}
                {schedule &&
                  schedule.recurrence &&
                  schedule.recurrence.by_day &&
                  this.getWeekDayName(schedule.recurrence.by_day).join(", ")}
              </div>
            );
          }
          if (
            schedule &&
            schedule.recurrence &&
            schedule.recurrence.frequency &&
            schedule.recurrence.frequency == "monthly"
          ) {
            returnVal = (
              <div className="text-capitalize">
                On Every Month{" "}
                {schedule &&
                  schedule.recurrence &&
                  schedule.recurrence.by_month_day &&
                  schedule.recurrence.by_month_day.join(", ")}
              </div>
            );
          }
          return returnVal;
        },
      },
      {
        title: getCurrentlocaleText("general.component.next.run.text"),
        key: "nextRun",
        dataIndex: "status",
        render: (obj, record, index) => {
          return (
            <div>
              {obj && obj.next_run
                ? moment(obj.next_run).format("DD MMM YYYY, h:mm A")
                : "-"}
            </div>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (obj, record, index) => {
          return <div> {obj && obj.state ? obj.state : "-"} </div>;
        },
      },
      {
        title: "",
        dataIndex: "",
        key: "action",
        render: (text, record, index) => {
          return (
            <>
              <Button
                type="text"
                className="p-5"
                onClick={() => this.redirectReport(record)}
              >
                View Reports
                <Icons
                  type="bs"
                  name="BsArrowUpRight"
                  style={{ fontSize: 18 }}
                />
              </Button>
            </>
          );
        },
      },
    ];

    const rowSelection = {
      selectedRowKeys,
      onChange: selectedRowKeys => {
        this.setState({ selectedRowKeys });
      },
    };

    return (
      <>
        <Row justify="space-between" wrap={false}>
          <Col flex="auto" className="py-15">
            {/* justify="space-between"  */}
            <Row className="scan-type-summary">
              <Col className="pr-15 py-15">
                <h3>
                  <span className="pr-5">
                    {getCurrentlocaleText("basic.scan")}
                  </span>
                  {this.BasicScanPopover()}
                </h3>
                <div className="device-discovery-useful-text">
                  {getCurrentlocaleText("scan.sub.useful.text")}
                </div>
                <div className="device-discovery-sub-text">
                  {getCurrentlocaleText("basic.scan.useful.text")}
                </div>
                <div className="text-muted since-last-scan">
                  {getCurrentlocaleText("time.scan.last")}
                </div>
                <div className="tile-date">
                  {this.getCount("basic", "last_scan")}
                </div>
                <div className="pt-10 last-scan-desc">
                  <span>
                    <b style={{ marginLeft: "2px" }}>
                      {this.getSummary("basic", "count")}
                    </b>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {getCurrentlocaleText("scan.ran.last")}
                  </span>
                </div>
              </Col>
              <Col className="p-15">
                <h3>
                  <span className="pr-5">
                    {getCurrentlocaleText("balanced.scan")}
                  </span>
                  {this.BalancedPopover()}
                </h3>
                <div className="device-discovery-useful-text">
                  {getCurrentlocaleText("scan.sub.useful.text")}
                </div>
                <div className="device-discovery-sub-text">
                  {getCurrentlocaleText("balanced.scan.useful.text")}
                </div>
                <div className="text-muted since-last-scan">
                  {getCurrentlocaleText("time.scan.last")}
                </div>
                <div className="tile-date">
                  {this.getCount("balanced", "last_scan")}
                </div>
                <div className="pt-10 last-scan-desc">
                  <span>
                    <b style={{ marginLeft: "2px" }}>
                      {this.getSummary("balanced", "count")}
                    </b>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {getCurrentlocaleText("scan.ran.last")}
                  </span>
                </div>
              </Col>
              <Col className="p-15">
                <h3>
                  <span className="pr-5">
                    {getCurrentlocaleText("deep.scan")}
                  </span>
                  {this.DeepScanPopover()}
                </h3>
                <div className="device-discovery-useful-text">
                  {getCurrentlocaleText("scan.sub.useful.text")}
                </div>
                <div className="device-discovery-sub-text">
                  {getCurrentlocaleText("deep.scan.useful.text")}
                </div>
                <div className="text-muted since-last-scan">
                  {getCurrentlocaleText("time.scan.last")}
                </div>
                <div className="tile-date">
                  {this.getCount("deep", "last_scan")}
                </div>
                <div className="pt-10 last-scan-desc">
                  <span>
                    <b style={{ marginLeft: "2px" }}>
                      {this.getSummary("deep", "count")}
                    </b>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {getCurrentlocaleText("scan.ran.last")}
                  </span>
                </div>
              </Col>
              <Col className="p-15">
                <h3>
                  <span className="pr-5">
                    {getCurrentlocaleText("bacnet.scan")}
                  </span>
                  {this.BacnetScanPopover()}
                </h3>
                <div className="device-discovery-useful-text">
                  {getCurrentlocaleText("scan.sub.useful.text")}
                </div>
                <div className="device-discovery-sub-text">
                  {getCurrentlocaleText("bacnet.scan.useful.text")}
                </div>
                <div className="text-muted since-last-scan">
                  {getCurrentlocaleText("time.scan.last")}
                </div>
                <div className="tile-date">
                  {this.getCount("bacnet", "last_scan")}
                </div>
                <div className="pt-10 last-scan-desc">
                  <span>
                    <b style={{ marginLeft: "2px" }}>
                      {this.getSummary("bacnet", "count")}
                    </b>
                  </span>
                  <span style={{ marginLeft: "8px" }}>
                    {getCurrentlocaleText("scan.ran.last")}
                  </span>
                </div>
              </Col>
            </Row>
          </Col>
          <Col flex="150px" className="py-15">
            <Button
              type="primary"
              className="border-radius-4"
              size="large"
              onClick={this.showModal}
              disabled={!this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN")}
            >
              <Icons
                type="fa"
                name="FaPlus"
                style={{ height: "18px", padding: "1px" }}
              />
              <span style={{ padding: "1px" }}>
                {getCurrentlocaleText("device.discovery.create.scan.text")}
              </span>
            </Button>
          </Col>
        </Row>

        <section className="mt-15">
          <Row justify="end">
            <Col span={12} className="search-wrapper">
              <Col span={13}>
                <Input
                  prefix={<Icons type="ai" name="AiOutlineSearch" />}
                  size="large"
                  placeholder="Search By Scan Name"
                  className="border-radius-4 search-input"
                  value={this.state.searchText}
                  onChange={this.onSearchChange}
                />
              </Col>
              <Col span={7}>
                <Select
                  size="large"
                  className="border-radius-8 search-btn"
                  icon={<Icons type="ai" name="AiOutlineFilter" />}
                  defaultValue=""
                  value={this.state.selScanType}
                  span={4}
                  onChange={this.onScanTypeChange}
                >
                  <Option value="" key={101}>
                    {getCurrentlocaleText(
                      "general.component.all.scans.type.text",
                    )}
                  </Option>
                  {scanType.map((obj, i) => {
                    return (
                      <Option value={obj.value} key={i}>
                        {obj.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Col>
            <Col span={12} className="d-flex flex-justify-right">
              <Radio.Group
                size="large"
                value={status}
                buttonStyle="solid"
                onChange={this.onScanStatusChange}
              >
                <Radio.Button value="inprogress">
                  {getCurrentlocaleText("general.component.current.scans")}
                </Radio.Button>
                <Radio.Button value="configured">
                  {getCurrentlocaleText("general.component.configured.scan")}
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>

          <Row justify="end">
            <Col span={24} className="report-compare-wrapper">
              <Button
                type="link"
                icon={<Icons type="ai" name="AiOutlineEdit" />}
                onClick={this.showEditModal}
                disabled={
                  selectedRows.length != 1 ||
                  !this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN")
                }
              >
                {getCurrentlocaleText("general.edit.text")}
              </Button>
              <Button
                type="link"
                icon={<Icons type="md" name="MdArrowForward" />}
                onClick={this.confirmRunNow}
                disabled={
                  selectedRows.length != 1 ||
                  isCurrentlyRunning ||
                  !this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN")
                }
              >
                {getCurrentlocaleText("run.now")}
              </Button>
              <Button
                type="link"
                icon={<Icons type="ai" name="AiOutlineDelete" />}
                onClick={this.confirmDelete}
                disabled={
                  selectedRows.length == 0 ||
                  !this.props.AuthStore.IsPermitted("DISCOVERY:ADMIN")
                }
              >
                {getCurrentlocaleText("remove")}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col span={24} className="flex-column">
              <LoadingComponent loading={this.deviceDisModel.loading}>
                <TableLayout
                  columns={columns}
                  dataSource={[...scanList]}
                  pagination={false}
                  className="width-full"
                  hasLazyLoading={true}
                  lazyLoad={this.lazyLoad}
                  scroll={{ y: 100 }}
                  showExpandColumn={false}
                  selectedRows={this.handleTableChange}
                  rowSelection={rowSelection}
                  selectAll={this.onSelectAllChange}
                  selctedRows={this.state.selectedRows}
                />
              </LoadingComponent>
            </Col>
          </Row>
        </section>

        {/* Create Scan */}
        {isModalOpen && (
          <Modal
            title={getCurrentlocaleText("create.scan.text")}
            visible={true}
            closable={true}
            maskClosable={false}
            className="device-discovery-modal"
            footer={
              <div className="justify-space-between">
                <Button
                  key="handlePrev"
                  type="link"
                  onClick={this.handlePrev}
                  className="border-radius-4"
                  disabled={currentStep === 0}
                >
                  {getCurrentlocaleText("back.text")}
                </Button>
                <div>
                  {currentStep < steps.length - 1 && (
                    <Button
                      key="handleNext"
                      type="default"
                      onClick={this.handleNext}
                      className="border-radius-4"
                    >
                      {getCurrentlocaleText("next.text")}
                    </Button>
                  )}
                  {currentStep === steps.length - 1 && (
                    <Button
                      key="ok"
                      type="default"
                      onClick={this.handleFinish}
                      className="border-radius-4"
                      loading={createScanLoading}
                      disabled={createScanLoading}
                    >
                      {getCurrentlocaleText("finish.text")}
                    </Button>
                  )}
                </div>
              </div>
            }
            width={800}
            onCancel={this.handleCancel}
          >
            <Steps
              current={currentStep}
              status="finish"
              size="small"
              className="pb-15"
            >
              {steps.map((step, index) => (
                <Step key={index} title={step.title} />
              ))}
            </Steps>
            <div className="mt-15">{steps[currentStep].content}</div>
          </Modal>
        )}

        {/* Edit Scan */}
        {isEditModalOpen && (
          <Modal
            title={getCurrentlocaleText("edit.scan.text")}
            visible={true}
            closable={true}
            maskClosable={false}
            className="device-discovery-modal"
            footer={[
              <>
                <Button
                  key="handleEditCancel"
                  type="default"
                  onClick={this.handleEditCancel}
                  className="border-radius-4"
                  disabled={isEditModalLoading}
                >
                  {getCurrentlocaleText("cancel.button.text")}
                </Button>
                <Button
                  key="handleEditFinish"
                  type="primary"
                  onClick={this.handleEditFinish}
                  className="border-radius-4"
                  disabled={isEditModalLoading}
                  loading={isEditModalLoading}
                >
                  {getCurrentlocaleText("general.button.confirm.label.text")}
                </Button>
              </>,
            ]}
            width={600}
            onCancel={this.handleEditCancel}
          >
            <Form
              ref={this.editFormRef}
              requiredMark={false}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 10,
              }}
            >
              <Form.Item
                label={getCurrentlocaleText("device.discovery.name.scan.text")}
                name="nameOfTheScan"
                rules={[
                  { required: true, message: "Name of the scan is required" },
                  {
                    min: 2,
                    message: getCurrentlocaleText(
                      "general.form.validation.min",
                      {
                        0: 2,
                      },
                    ),
                  },
                  {
                    max: 30,
                    message: getCurrentlocaleText(
                      "general.form.validation.max",
                      {
                        0: 30,
                      },
                    ),
                  },
                ]}
                initialValue={editData.name}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={getCurrentlocaleText("general.component.networks.text")}
                name="network"
                rules={[{ required: true, message: "Network is required" }]}
                initialValue={
                  editData && editData.network && editData.network.id
                    ? editData.network.id
                    : ""
                }
              >
                <Select allowClear disabled={true}>
                  {iNodeNetworks &&
                    Array.isArray(iNodeNetworks) &&
                    iNodeNetworks.map(obj => {
                      return (
                        <Option value={obj.id} key={obj.id}>
                          {obj.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item
                label={getCurrentlocaleText("general.component.scan.type.text")}
                name="scanType"
                rules={[{ required: true, message: "Scan Type is required" }]}
                initialValue={editData.discovery_profile}
              >
                <Select allowClear disabled={true}>
                  {scanType.map((obj, i) => {
                    return (
                      <Option value={obj.value} key={i}>
                        {obj.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                label={getCurrentlocaleText("general.component.frequency.text")}
                name="frequency"
                rules={[{ required: true, message: "Frequency is required" }]}
                initialValue={editData.recurrenceType}
              >
                <Radio.Group className="d-flex" disabled={true}>
                  {frequencyType.map((obj, id) => {
                    return (
                      <Radio value={obj.value} key={id}>
                        {obj.label}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </Form.Item>

              {editData.recurrenceType == "recurrence" && (
                <>
                  <Form.Item
                    label={getCurrentlocaleText(
                      "general.component.interval.text",
                    )}
                    name="interval"
                    rules={[
                      { required: true, message: "Interval is required" },
                    ]}
                    initialValue={editData.schedule.recurrence.frequency}
                  >
                    <Select
                      onChange={this.onFrequencyEditIntervalChange}
                      allowClear
                    >
                      <Option value="daily">
                        {getCurrentlocaleText("daily")}
                      </Option>
                      <Option value="weekly">
                        {getCurrentlocaleText("weekly")}
                      </Option>
                      <Option value="monthly">
                        {getCurrentlocaleText("monthly")}
                      </Option>
                    </Select>
                  </Form.Item>

                  {editData.schedule &&
                    editData.schedule.recurrence &&
                    editData.schedule.recurrence.frequency && (
                      <>
                        {/* Daily */}
                        {editData.schedule.recurrence.frequency == "daily" && (
                          <Form.Item
                            label={getCurrentlocaleText(
                              "general.component.repeat.every.text",
                            )}
                            name="RepeatEvery"
                            rules={[
                              {
                                required: true,
                                message: "Repeat Every is required",
                              },
                            ]}
                            initialValue={editData.schedule.recurrence.interval}
                          >
                            <Select allowClear>
                              {this.optionList(12, " Hour Once")}
                            </Select>
                          </Form.Item>
                        )}

                        {/* Weekly */}
                        {editData.schedule.recurrence.frequency == "weekly" && (
                          <Form.Item
                            label={getCurrentlocaleText("weekly")}
                            name="weekly"
                            rules={[
                              { required: true, message: "Weekly is required" },
                            ]}
                            initialValue={editData.schedule.recurrence.by_day}
                          >
                            <Checkbox.Group
                              options={weeklyOptions}
                              onChange={this.onWeeklyChange}
                              className="on-days"
                            />
                          </Form.Item>
                        )}

                        {/* Monthly */}
                        {editData.schedule.recurrence.frequency ==
                          "monthly" && (
                          <>
                            <Form.Item
                              label={getCurrentlocaleText(
                                "device.discovery.on.days.text",
                              )}
                              name="onDays"
                              mode="multiple"
                              rules={[
                                {
                                  required: true,
                                  message: "On Days is required",
                                },
                              ]}
                              initialValue={
                                editData.schedule.recurrence.by_month_day
                              }
                            >
                              <Select mode="multiple" allowClear>
                                {this.optionList(31)}
                              </Select>
                            </Form.Item>
                          </>
                        )}

                        {/* Scheduled Date Time */}
                        {editData.schedule &&
                          editData.schedule.recurrence &&
                          editData.schedule.recurrence.start_time && (
                            <Form.Item
                              label={getCurrentlocaleText(
                                "general.component.scheduled.text",
                              )}
                              name="time"
                              rules={[
                                {
                                  required: true,
                                  message: "Date/Time is required",
                                },
                              ]}
                              initialValue={moment(
                                editData.schedule.recurrence.start_time,
                              ).tz(this.timezone)}
                            >
                              <DatePicker
                                showTime={{
                                  defaultValue: moment(),
                                }}
                                format="YYYY-MM-DD h:mm A"
                                placeholder={getCurrentlocaleText(
                                  "event.startdatefilter.placeholder",
                                )}
                                onChange={value => {
                                  this.setState({
                                    timeSel: moment(value),
                                  });
                                }}
                                disabledDate={this.disabledDate}
                                title={getCurrentlocaleText(
                                  "event.startdatefilter.placeholder",
                                )}
                              />
                            </Form.Item>
                          )}
                      </>
                    )}
                </>
              )}
            </Form>
          </Modal>
        )}

        {/* Delete Scan */}
        {isDeleteModalOpen && (
          <Modal
            title={getCurrentlocaleText("delete.scan.text")}
            visible={true}
            closable={true}
            maskClosable={false}
            footer={[
              <>
                <Button
                  key="handleDeleteCancel"
                  type="default"
                  onClick={this.handleDeleteCancel}
                  className="border-radius-4"
                >
                  {getCurrentlocaleText("cancel.button.text")}
                </Button>
                <Button
                  key="handleDelete"
                  type="primary"
                  onClick={this.handleDelete}
                  className="border-radius-4"
                  disabled={isDeleteModalLoading}
                  loading={isDeleteModalLoading}
                >
                  {getCurrentlocaleText("general.button.confirm.label.text")}
                </Button>
              </>,
            ]}
            width={600}
            onCancel={this.handleDeleteCancel}
          >
            <p>
              {getCurrentlocaleText("inode.delete.confirmation.message3.text")}
            </p>
            {selectedRows &&
              selectedRows.map((obj, index) => {
                return (
                  <Tag
                    key={index}
                    title={index}
                    className="tag-iotium-info tag-iotium-ellipse"
                  >
                    {obj.name}
                  </Tag>
                );
              })}
          </Modal>
        )}

        {/* Run Now Scan */}
        {isRunNowModalOpen && (
          <Modal
            title={getCurrentlocaleText("scan.now")}
            visible={true}
            closable={true}
            maskClosable={false}
            footer={[
              <>
                <Button
                  key="handleRunNowCancel"
                  type="default"
                  onClick={this.handleRunNowCancel}
                  className="border-radius-4"
                >
                  {getCurrentlocaleText("cancel.button.text")}
                </Button>
                <Button
                  key="handleRunNow"
                  type="primary"
                  onClick={this.handleRunNow}
                  className="border-radius-4"
                  disabled={isRunNowModalLoading}
                  loading={isRunNowModalLoading}
                >
                  {getCurrentlocaleText("general.button.confirm.label.text")}
                </Button>
              </>,
            ]}
            width={600}
            onCancel={this.handleRunNowCancel}
          >
            <p>
              {getCurrentlocaleText("inode.scan.confirmation.message3.text")}
            </p>
            {selectedRows &&
              selectedRows.map((obj, index) => {
                return (
                  <Tag
                    key={index}
                    title={index}
                    className="tag-iotium-info tag-iotium-ellipse"
                  >
                    {obj.name}
                  </Tag>
                );
              })}
          </Modal>
        )}
      </>
    );
  }
}
